.active {
	background: rgba(190, 190, 190, 0.3);
}

.nav-items {
	font-size: 1.2vw;
	width: 96%;
	padding-left: 1.5vw;
	align-items: center;
	border-radius: 0.4vw;
}

.nav-items:hover {
	background: rgba(190, 190, 190, 0.3);
	cursor: pointer;
}

.nav-items-icon {
	font-size: 1vw;
}

.nav-items p {
	margin-left: 1vw;
}

.nav-items:hover p {
	color: #000000;
	font-weight: 500;
}

.nav-items:hover svg {
	color: #000000;
}

.nav-items {
	margin-bottom: 1rem;
}

.user-icon:hover {
	background-color: rgba(190, 190, 190, 0.8);
	font-weight: 500;
	cursor: pointer;
}

.user-icon:hover p {
	color: #000000;
	font-weight: 500;
}

.conclude-tile {
	border-radius: 1vw;
	/* border-width: 0.1vw; */
}

.component-dashboard:hover {
	-moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
	-webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
