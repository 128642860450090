html,
body {
	width: 100%;
	height: 100%;
}

.landing-body {
	background: linear-gradient(-45deg, #23a6d5, #23d5ab);
	/* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
	background-size: 300% 300%;
	animation: gradient 7s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.landing-slogan {
	animation: easeIn 0.5s ease;
}

@keyframes easeIn {
	from {
		color: transparent;
		margin-left: -3vw;
	}
	to {
		color: white;
		margin-left: 0vw;
	}
}

.slogan-list {
	overflow: hidden;
}

.slogan-list-item {
	color: white;
	animation: slide-up 7s infinite;
}

@keyframes slide-up {
	0%,
	30% {
		transform: translateY(0%);
	}
	35%,
	60% {
		transform: translateY(-100%);
	}
	65%,
	95% {
		transform: translateY(-200%);
	}
}

/* .landing-subtitle {
	border-right: 2px solid hsl(0, 0%, 80%);
	animation: typewriter 7s steps(55) normal, blinkTextCursor 0.8s infinite;
	white-space: nowrap;
	overflow: hidden;
} */

/* @keyframes blinkTextCursor {
	from {
		border-right-color: white;
	}
	to {
		border-right-color: transparent;
	}
}

@keyframes typewriter {
	from {
		width: 0;
	}
	to {
		width: 34vw;
	}
}  */
